import React, { useEffect, useState } from 'react'
import { LocalStorage } from '../../components/lib-atomic-functions'
import { Box, Typography } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import PinnedBoxContainer from '../../components/app-module-pinned-box-container/default'
import { useCookies } from 'react-cookie'

import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'

import appConf from '../../components/app-conf'
import Link from '../../components/lib-atomic/link'
import TipBannerSection from '../../components/app-section-tip-banner'

const logo_sm = appConf[process.env.REACT_APP_CLIENT].assets.logo_sm
const logo_twitter = appConf[process.env.REACT_APP_CLIENT].assets.logo_twitter
const logo_facebook = appConf[process.env.REACT_APP_CLIENT].assets.logo_facebook
const logo_instagram =
  appConf[process.env.REACT_APP_CLIENT].assets.logo_instagram

const useStyles = makeStyles((theme) => ({
  links: {
    textTransform: 'uppercase',
    fontSize: '0.8em',
    fontWeight: 'bold',
    '& *': {
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(1),
    },
  },
  social: {
    '& *': {
      padding: theme.spacing(1),
    },
  },
  popover: {
    pointerEvents: 'none',
    marginLeft: 30,
  },
  paper: {
    padding: theme.spacing(1),
    color: '#008938',
  },
}))

const Footer = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'strictCookie',
    'performanceCookie',
  ])
  const { pinnedMessage } = LocalStorage.getLS()
  const [showSettings, setShowSettings] = useState(false)
  const [toggleModal1, setToggleModal1] = useState(false)

  useEffect(() => {
    if (cookies.performanceCookie === undefined) {
      setShowSettings(false)
    } else {
      setShowSettings(true)
    }
  }, [cookies])
  useEffect(() => {
    if (pinnedMessage) {
      const { isCookieBox } = pinnedMessage
      if (!isCookieBox) {
        setShowSettings(true)
      }
    }
  }, [])
  const handleShowSettingsIcon = () => {
    setShowSettings(!showSettings)
  }
  const grabToggleModalStatus = () => {
    return toggleModal1
  }
  const classes = useStyles()
  const { lang } = LocalStorage.getLS()
  const currentlanguage = LocalStorage.getLS().translation.json
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const newProps = {
    ...props,
    grabToggleModalStatus,
    setToggleModal1,
  }
  return (
    <div>
      <div className="green-link">
        <PinnedBoxContainer {...newProps} />
      </div>
      <Box
        component="footer"
        color="primary.contrastText"
        bgcolor="primary.main"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box component={Link} to="/home" py={2}>
          <img src={logo_sm} alt="logo" width="42" />
        </Box>

        <Typography
          align="center"
          color="textSecondary"
          paragraph
          className={classes.links}
        >
          <a className='subway-footlong' href="https://www.subway.com/fr-FR/Privacy/PrivacyCenter">
            {currentlanguage.footer.footer_privacy_notice}
          </a>{' '}
          |{' '}
          <Link to="/terms">
            {currentlanguage.footer.footer_terms_and_conditions}
          </Link>{' '}
          | <Link to="/faq">{currentlanguage.footer.footer_faqs}</Link> |{' '}
          <Link to="/cookies">
            {currentlanguage.footer.footer_cookie_policy}
          </Link>{' '}
          |{' '}
          <Link to="/promotions">
            {currentlanguage.footer.footer_promotion_terms_and_conditions}
          </Link>
        </Typography>

        <TipBannerSection {...props} />

        <Typography
          variant="body2"
          align="center"
          style={{ width: '70%' }}
          className="subway-six-inch"
          >
          {/* <span
            dangerouslySetInnerHTML={{
              __html: currentlanguage.footer.footer_body,
            }}
          /> */}
          <span>© {new Date().getFullYear()} Subway IP LLC. Subway® est une marque enregistrée de Subway IP LLC. Uniquement dans les restaurants participants.</span>
    
          <Link to="/impressum" color="textSecondary">
            {currentlanguage.footer.footer_body_imprint}
          </Link>
        </Typography>

        <Box py={1} className={classes.social}>
          {/* <a
          href={
            lang.toLowerCase() === 'de'
              ? 'https://twitter.com/Subway_DE'
              : 'https://twitter.com/Subway_DE'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo_twitter} alt="Twitter" />
        </a> */}
          <a
            href={
              currentlanguage.footer.footer_facebook_link &&
              currentlanguage.footer.footer_facebook_link.length !== 0
                ? currentlanguage.footer.footer_facebook_link
                : '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logo_facebook} alt="Facebook" />
          </a>
          <a
            href={
              currentlanguage.footer.footer_instagram_link &&
              currentlanguage.footer.footer_instagram_link.length !== 0
                ? currentlanguage.footer.footer_instagram_link
                : '#'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={logo_instagram} alt="Instagram" />
          </a>
        </Box>
        {/* {showSettings ? (
          <div
            style={{
              position: 'fixed',
              bottom: 15,
              left: 15,
              borderRadius: 15,
              backgroundColor: '#008938',
              padding: 3,
              alignSelf: 'center',
            }}
          >
            <SettingsIcon
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{ width: 25, height: 25, color: 'white' }}
              onClick={() => {
                setToggleModal1(!toggleModal1)
              }}
            />

            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography className="subway-six-inch">
                {currentlanguage.footer.footer_setting_toggle_text}
              </Typography>
            </Popover>
          </div>
        ) : null} */}
      </Box>
    </div>
  )
}

export default Footer
